import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { InitialState } from './types/DashboardTypes';

const api = process.env.REACT_APP_API;

export const getProjectData = createAsyncThunk(
  'get/project-data',
  async (): Promise<any> => {
    const response = await axios.get(`${api}/projects/counts`);

    return response.data.project_data;
  }
);

export const getProductData = createAsyncThunk(
  'get/products-and-services-data',
  async (): Promise<any> => {
    const response = await axios.get(`${api}/products-and-services/counts`);

    return response.data.product_data;
  }
);

const initialState: InitialState = {
  projects: {
    categories: {
      all_projects: [],
      completed_projects: [],
      ongoing_projects: [],
      advanced_payment_not_paid: [],
      final_invoice_not_paid: [],
    },
    pending: false,
    error: false,
  },
  products: {
    list: [],
    pending: false,
    error: false,
  },
};

const DashboardSlice = createSlice({
  name: 'invoice',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProjectData.fulfilled, (state, action) => {
      state.projects.categories = action.payload;
    });
    builder.addCase(getProductData.fulfilled, (state, action) => {
      state.products.list = action.payload;
    });
  },
});

export default DashboardSlice.reducer;
