import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import type { InitialState } from './types/ProjectTypes'

const api = process.env.REACT_APP_API

export const getOneProject = createAsyncThunk(
  'projects/getOne',
  async (id: any): Promise<any> => {
    const response = await axios.get(`${api}/projects/${id}`)

    return response.data
  }
)

export const getProposal = createAsyncThunk(
  'proposal/get',
  async (id: any): Promise<boolean> => {
    const response = await axios.get(`${api}/proposals/${id}`)
    if (response.data.proposal !== null) {
      return true
    } else {
      return false
    }
  }
)

export const getAllProjects = createAsyncThunk(
  'projects/getAll',
  async (filters: any): Promise<any> => {
    const response = await axios.get(
      `${api}/projects?search=${filters.search}&page=${filters.page}&limit=${filters.limit}`
    )

    return response.data
  }
)

export const postProject = createAsyncThunk(
  'projects/post',
  async (data: any): Promise<any> => {
    const response = await axios.post(`${api}/projects`, data)

    return response.data.project
  }
)

export const updateProject = createAsyncThunk(
  'projects/put',
  async (data: any): Promise<any> => {
    const response = await axios.put(`${api}/projects/${data.id}`, data.body)

    return response.data.project
  }
)

export const statusUpdate = createAsyncThunk(
  'projects/update-status',
  async (data: any): Promise<any> => {
    const response = await axios.put(
      `${api}/projects/status/${data.id}`,
      data.body
    )

    return response.data
  }
)

export const postProposal = createAsyncThunk(
  'proposal/get',
  async (data: any) => {
    const response = await axios.post(`${api}/proposals/${data.id}`, data.data)

    return response.data
  }
)

export const emailProposal = createAsyncThunk(
  'proposal/email',
  async (data: any) => {
    const response = await axios.post(
      `${api}/proposals/email/${data.id}`,
      data.data
    )

    return response.data
  }
)

export const imageUpload = createAsyncThunk(
  'project/installaion-images',
  async (data: any) => {
    const response = await axios.post(
      `${api}/projects/installation-images/${data.id}`,
      data.data
    )

    return response.data.project
  }
)

export const deleteProject = createAsyncThunk(
  'projects/delete',
  async (id: any) => {
    const response = await axios.delete(`${api}/projects/${id}`)

    return response.data
  }
)

const initialState: InitialState = {
  pending: false,
  error: false,
  project: {
    id: '',
    customer_id: '',
    date: '',
    status: '',
    email_address: '',
    contact_number: '',
    site_address: [],
    gps_coordinates: {
      lat: 0,
      lon: 0
    },
    avg_monthly_usage: 0,
    avg_monthly_bill: 0,
    phase: '',
    amperage: '',
    wifi: false,
    roof_shading: 0,
    payment_option: '',
    electricity_acc_no: '',
    system_method: '',
    utility: '',
    panel_capacity: 0,
    inverter_capacity: 0,
    roof_type: '',
    roof_access: '',
    ladder_type: '',
    other_comments: '',
    bill_img_url: '',
    id_img_url: '',
    entrance_img_url: '',
    bank_account_img_url: '',
    proposal: false,
    installation_images: []
  },
  project_list: {
    results: [],
    total_pages: 0
  },
  post: {
    pending: false,
    success: false,
    error: false
  },
  email: {
    error: false,
    pending: false,
    success: false
  }
}

const ProjectSlice = createSlice({
  name: 'project',
  initialState: initialState,
  reducers: {
    resetPostStatus (state) {
      state.post.success = false
    }
  },
  extraReducers: builder => {
    builder.addCase(getAllProjects.fulfilled, (state, action) => {
      state.project_list.results = action.payload.projects
      state.project_list.total_pages = action.payload.total_pages
    })
    builder.addCase(getOneProject.fulfilled, (state, action) => {
      state.project = action.payload.project
      state.project.logs = action.payload.logs
    })
    builder.addCase(updateProject.fulfilled, (state, action) => {
      state.project = action.payload
    })
    builder.addCase(statusUpdate.fulfilled, (state, action) => {
      state.project = action.payload.project
      state.project.logs = action.payload.logs
    })
    builder.addCase(imageUpload.fulfilled, (state, action) => {
      state.project = action.payload
    })
    builder.addCase(postProject.pending, state => {
      state.post.error = false
      state.post.pending = true
    })
    builder.addCase(postProject.rejected, state => {
      state.post.pending = false
      state.post.error = true
    })
    builder.addCase(postProject.fulfilled, (state, action) => {
      state.post.pending = false
      state.post.error = false
      state.project = action.payload
      state.post.success = true
    })
    builder.addCase(getProposal.fulfilled, (state, action) => {
      state.pending = false
      state.error = false
      state.project.proposal = action.payload
    })
    builder.addCase(emailProposal.fulfilled, (state, action) => {
      if (action.payload.message === 'Successfull') {
        state.email.error = false
        state.email.success = true
      } else {
        state.email.error = true
        state.email.success = false
      }
    })
  }
})

export const { resetPostStatus } = ProjectSlice.actions

export default ProjectSlice.reducer
