import React from 'react';
import { navbarHeight, footerHeight } from '../utils/constants';

const Layout = ({ children, className }: any) => {
    return (
        <div
            className={`w-full ${className}`}
            style={{
                height: `calc(100vh - ${navbarHeight + footerHeight}px)`,
                overflowY: 'auto',
            }}
        >
            {children}
        </div>
    );
};

export default Layout;
