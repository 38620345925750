import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import type { InitialState, User } from './types/UserTypes';
import jwt from 'jwt-decode';

const api = process.env.REACT_APP_API;

export const postLogin = createAsyncThunk('login', async (data: User) => {
  const response = await axios.post(`${api}/user/login`, data);
  localStorage.setItem('authorization', response.headers.token || '');
  localStorage.setItem('user', JSON.stringify(response.data.user));

  return response.data.user;
});

export const getAllUsers = createAsyncThunk('users/get', async () => {
  const response = await axios.get(`${api}/users`);

  return response.data.users;
});

export const updateUser = createAsyncThunk(
  'users/update',
  async (data: any) => {
    const response = await axios.put(`${api}/users/${data.id}`, data, {
      headers: { authorization: localStorage.getItem('authorization') },
    });

    return response.data.user;
  }
);

export const deleteUser = createAsyncThunk(
  'users/delete',
  async (userId: string | undefined, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${api}/users/${userId}`, {
        headers: { authorization: localStorage.getItem('authorization') },
      });

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const postUser = createAsyncThunk(
  'users/post',
  async (body: any, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${api}/user/sign-up`, body);

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUserTasks = createAsyncThunk(
  'users/tasks/get',
  async (user_id: string) => {
    const response = await axios.get(`${api}/tasks/${user_id}`);

    return response.data.tasks;
  }
);

export const setCurrentUser = createAsyncThunk(
  'users/set-user',
  async (token: string) => {
    const user: User = await jwt(token);

    return user;
  }
);

export const getOneUser = createAsyncThunk(
  'users/get-one',
  async (userId: string | undefined) => {
    const response = await axios.get(`${api}/users/${userId}`);

    return response.data;
  }
);

const initialState: InitialState = {
  user: {
    user_id: '',
    user_name: '',
    admin: false,
    tasks: [],
  },
  one_user: {
    user_id: '',
    user_name: '',
    admin: false,
    tasks: [],
  },
  delete: {
    success: false,
    error: false,
    message: '',
  },
  loggedIn: false,
  pending: false,
  error: false,
  all_users: [],
  post: {
    new_user: {},
    error: false,
    message: '',
  },
};

const UserSlice = createSlice({
  name: 'users',
  initialState: initialState,
  reducers: {
    resetPostStatus(state) {
      state.post.message = '';
      state.post.error = false;
    },
    resetDelete(state) {
      state.delete.error = false;
      state.delete.message = '';
      state.delete.success = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.all_users = action.payload;
    });
    builder.addCase(postUser.fulfilled, (state, action) => {
      state.post.error = false;
      state.post.new_user = action.payload.new_user;
      state.post.message = action.payload.message;
    });
    builder.addCase(postUser.rejected, (state, action: any) => {
      state.post.error = true;
      state.post.message = action.payload.message;
    });
    builder.addCase(getUserTasks.fulfilled, (state, action) => {
      state.user.tasks = action.payload;
    });
    builder.addCase(setCurrentUser.fulfilled, (state, action) => {
      state.user.user_id = action.payload.user_id;
      state.user.user_name = action.payload.user_name;
      state.user.admin = action.payload.admin;
    });
    builder.addCase(getOneUser.fulfilled, (state, action) => {
      state.one_user = action.payload.user;
    });
    builder.addCase(deleteUser.rejected, (state, action: any) => {
      state.delete.message = action.payload.message;
      state.delete.error = true;
      state.delete.success = false;
    });
    builder.addCase(deleteUser.fulfilled, (state, action: any) => {
      state.delete.message = action.payload.message;
      state.delete.error = false;
      state.delete.success = true;
    });
  },
});

export const { resetPostStatus, resetDelete } = UserSlice.actions;

export default UserSlice.reducer;
