import React from 'react';

type Props = {
  tasks: any[];
  projects: any[];
};

const Tasks: React.FC<Props> = ({ tasks, projects }) => {
  return (
    <div className="flex w-5/6">
      <div className="bg-white rounded mt-5 shadow-xl p-6 w-1/2 mr-3 min-h-full">
        <h2 className="font-semibold ">MY TASKS</h2>
        <table className="my-2 w-full">
          <thead>
            <tr className="text-sm font-semibold">
              <td>PROJECT ID</td>
              <td className="text-center">CUSTOMER</td>
              <td className="text-center">TASK</td>
              <td className="text-right">DATE ASSIGNED</td>
            </tr>
          </thead>
          <tbody>
            {tasks.map((task) => (
              <tr className="text-sm">
                <td>{task.project_id}</td>
                <td className="text-center">
                  {task.customer?.first_name + ' ' + task.customer?.last_name}
                </td>
                <td className="text-center">{task.status}</td>
                <td className="text-right">{task.updatedAt?.split('T')[0]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="bg-white rounded mt-5 shadow-xl p-6 w-1/2 ml-3 min-h-full">
        <h2 className="font-semibold ">RECENT PROJECTS</h2>
        <table className="my-2 w-full">
          <thead>
            <tr className="text-sm font-semibold">
              <td>PROJECT ID</td>
              <td className="text-center">CUSTOMER</td>
              <td className="text-center">STATUS</td>
              <td className="text-right">START DATE</td>
            </tr>
          </thead>
          <tbody>
            {projects.map((task) => (
              <tr className="text-sm">
                <td>{task.project_id}</td>
                <td className="text-center">
                  {task.customer?.first_name + ' ' + task.customer?.last_name}
                </td>
                <td className="text-center">{task.status}</td>
                <td className="text-right">{task.createdAt?.split('T')[0]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Tasks;
