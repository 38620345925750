import React, { useState } from 'react';
import logo from '../images/logo.png';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { CgClose } from 'react-icons/cg';
import { navbarHeight } from '../utils/constants';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import NavDrawer from './Drawer';

const Header = () => {
  const navigate = useNavigate();
  const [user_options, setuser_options] = useState(false);
  const [logout_modal, setlogout_modal] = useState(false);

  const user = useSelector((state: RootState) => state.user.user);

  const confirmLogout = () => {
    localStorage.removeItem('authorization');
    // Navigate to login page
    navigate('/login');
  };

  const UserOptions = () => {
    return (
      <ul
        className='absolute right-2 rounded text-sm bg-white shadow-lg w-40 fade-down '
        style={{
          top: '80px',
        }}
      >
        <li
          className='px-5 py-2 hover:bg-gray-100 cursor-pointer '
          role='button'
          onMouseDown={() => setlogout_modal(true)}
        >
          Logout
        </li>
      </ul>
    );
  };

  const LogOut = () => {
    return (
      <div className='z-20 absolute bg-black/75 w-screen h-screen flex items-center justify-center top-0 left-0 '>
        <button
          onClick={() => setlogout_modal(false)}
          className='absolute top-10 right-12 text-white text-4xl'
        >
          <CgClose />
        </button>
        <div className='bg-white flex flex-col items-center p-20 rounded font-semibold'>
          <AiOutlineCloseCircle className='mb-8 text-red-500 text-6xl' />
          <h2 className='text-3xl capitalize mb-1'>Are you sure?</h2>
          <p className='text-gray-500'>Are you sure you want to sign out?</p>
          <div className='flex justify-around mt-10 gap-2'>
            <button
              onClick={() => setlogout_modal(false)}
              className='bg-gray-200 text=gray-800 py-2 px-5 rounded font-bold text-sm shadow-lg hover:shadow-xl hover:bg-gray-300 duration-100'
            >
              CANCEL
            </button>

            <button
              onClick={() => confirmLogout()}
              className='uppercase bg-red-600 py-2 px-5 rounded text-white font-bold text-sm shadow-lg hover:shadow-xl hover:bg-red-700 duration-100'
            >
              Sign Out
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className='shadow w-full bg-white py-3 px-6 flex justify-between border-b-2 border-orange-400'
      style={{ height: `${navbarHeight}px` }}
    >
      <img className='py-4 px-6 h-24 rounded-xl ' alt='Sunterra' src={logo} />
      <div className='flex items-center gap-2'>
        {/* Avatar */}
        <button
          onClick={() => setuser_options(!user_options)}
          onBlur={() => setuser_options(false)}
          className='flex items-center gap-2 text-lg '
        >
          <span className='relative bg-orange-200 rounded-full text-sm h-10 w-10 flex justify-center items-center font-bold'>
            {user.user_name.charAt(0).toUpperCase()}
          </span>
        </button>
        {/* Avatar Menu */}
        {user_options && <UserOptions />}
        {logout_modal && <LogOut />}

        {/* NavDrawer Mobile */}
        <NavDrawer />
      </div>
    </div>
  );
};

export default Header;
