import React from 'react';
import { ProjectData } from '../../store/types/DashboardTypes';
import { Link } from 'react-router-dom';

type Props = {
  project_data: ProjectData;
};

const Projects: React.FC<Props> = ({ project_data }) => {
  return (
    <div className='flex flex-col w-5/6 bg-white shadow-xl p-6'>
      <h2 className='font-semibold mb-5'>PROJECTS SUMMARY</h2>
      <div className='flex flex-col lg:flex-row justify-around  rounded relative  gap-3'>
        <button className='shadow hover:bg-gray-50 rounded p-5 text-center border w-full relative'>
          <h3 className='font-bold text-3xl text-orange-800 text-center mb-3'>
            {project_data.all_projects.length}
          </h3>
          <h3 className='text-xs font-semibold text-gray-800'>
            Total Projects
          </h3>
        </button>
        <button className='shadow hover:bg-gray-50 rounded p-5 text-center border w-full relative'>
          <h3 className='font-bold text-3xl text-orange-800 text-center mb-3'>
            {project_data.ongoing_projects.length}
          </h3>
          <h3 className='text-xs font-semibold text-gray-800'>
            Ongoing Projects
          </h3>
        </button>
        <button className='shadow hover:bg-gray-50 rounded p-5 text-center border w-full relative'>
          <h3 className='font-bold text-3xl text-orange-800 text-center mb-3'>
            {project_data.completed_projects.length}
          </h3>
          <h3 className='text-xs font-semibold text-gray-800'>
            Completed Projects
          </h3>
        </button>
        <button className='shadow hover:bg-gray-50 rounded p-5 text-center border w-full relative'>
          <h3 className='font-bold text-3xl text-orange-800 text-center mb-3'>
            {project_data.advanced_payment_not_paid.length}
          </h3>
          <h3 className='text-xs font-semibold text-gray-800'>
            Advanced Payment TBP
          </h3>
        </button>
        <button className='shadow hover:bg-gray-50 rounded p-5 text-center border w-full relative'>
          <h3 className='font-bold text-3xl text-orange-800 text-center mb-3'>
            {project_data.final_invoice_not_paid.length}
          </h3>
          <h3 className='text-xs font-semibold text-gray-800'>
            Final Payment TBP
          </h3>
        </button>
      </div>
      <Link
        className='ml-auto mt-5  text-xs font-semibold text-gray-500 hover:text-gray-700 hover:underline ease-in-out'
        to='/projects'
      >
        View Details
      </Link>
    </div>
  );
};

export default Projects;
