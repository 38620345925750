import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { navbarHeight, footerHeight } from '../utils/constants';
import { links } from '../data/routes';

const NavBar: React.FC = () => {
  const location = useLocation();
  return (
    <div
      className='w-64 shadow-lg py-1 z-10 hidden md:block'
      style={{
        height: `calc(100vh - ${navbarHeight + footerHeight}px)`,
        overflowY: 'auto',
      }}
    >
      <ul>
        {links.map((link: any, idx: Number) => {
          return (
            <li className='w-full' key={idx.toString()}>
              <Link
                className={`flex items-center gap-4 py-4 px-4 font-semibold text-sm w-full ${location.pathname.includes(link.path) && 'bg-gray-100 '
                  } hover:bg-gray-50 `}
                to={link.path}
              >
                <span
                  className={`
                   ${location.pathname.includes(link.path)
                      ? 'animate-bounce text-orange-400'
                      : 'text-gray-200'
                    }  `}
                  style={{
                    animationTimingFunction: 'ease-out',
                    animationDirection: 'forwards',
                    animationIterationCount: '1.5',
                    animationDuration: '0.6s',
                    animationComposition: 'additive',
                  }}
                >
                  {link.icon}
                </span>
                {link.name}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default NavBar;
