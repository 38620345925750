import React, { useState } from 'react';
import { Drawer, IconButton, List } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../images/logo.png';
import { links } from '../data/routes';
import { Link, useLocation } from 'react-router-dom';

const NavDrawer = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const location = useLocation();

  return (
    <div className='md:hidden block mx-auto mr-3 overflow-hidden'>
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <List sx={{ width: '260px', fontFamily: 'Poppins' }}>
          <img
            className='py-4 px-6 h-24 rounded-xl mb-3'
            alt='Sunterra'
            src={logo}
          />
          {links.map((link: any, idx: Number) => {
            return (
              <li className='w-full' key={idx.toString()}>
                <Link
                  className={`flex items-center gap-4 py-4 px-4 font-semibold text-sm w-full ${
                    location.pathname.includes(link.path) && 'bg-gray-100 '
                  } hover:bg-gray-50 `}
                  to={link.path}
                  onClick={() => setOpenDrawer(false)}
                >
                  <span
                    className={`
                   ${
                     location.pathname.includes(link.path)
                       ? 'animate-bounce text-orange-400'
                       : 'text-gray-200'
                   }  `}
                    style={{
                      animationTimingFunction: 'ease-out',
                      animationDirection: 'forwards',
                      animationIterationCount: '1.5',
                      animationDuration: '0.6s',
                      animationComposition: 'additive',
                    }}
                  >
                    {link.icon}
                  </span>
                  {link.name}
                </Link>
              </li>
            );
          })}
        </List>
      </Drawer>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
        <MenuIcon />
      </IconButton>
    </div>
  );
};

export default NavDrawer;
